import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/layout/Layout"
import Container from "../../components/layout/Container"
import Type from "../../components/typography/Type"
import SearchNav from "../../components/search/SearchNav"
import { isLoggedIn } from "../../services/auth"
import LoginPrompt from "../../components/app/loginPrompt"


const Projects = ({ data, pageContext }) => (

<Layout>
<Container>

  {isLoggedIn() ? (
 
 <div>
 
  <div className="flex flex-row space-x-24">
    <Type type="h2">Projects</Type>
    <div className="grow">
      <div className="flex justify-end">
        <a href="/new/projects">
          <button className="bg-black/10 border hover:bg-white text-grey hover:text-black px-8 py-4 rounded-xl">Add a project</button>
        </a>
      </div>
    </div>
  </div>
      
  <div className="flex flex-col space-y-16">
    <div className="grid grid-cols-2 lg:grid-cols-4 mt-16 gap-8">
      {data.allAirtable.edges.map((edge) => (
        <a href={edge.node.data.Path}>
          <div className="bg-orange p-8 rounded-lg">
            <Type type="h3">{edge.node.data.Name}</Type>
          </div>
        </a>
      ))}
    </div>
  </div>
</div>

 
) : (
  <>
    <LoginPrompt/>
  </>
)}




</Container>
</Layout>


)

export const Head = () => <title>Projects</title>

export default Projects

// query airtable for the Title and Path of each record,
// filtering for only records in the Sections table.
// sort: {fields: data___Order, order: ASC}
    
export const query = graphql` 
query ($skip: Int!, $limit: Int!) {
  allAirtable (
    filter: {queryName: {eq: "Projects"}}
    skip: $skip
    limit: $limit
  ) {
    edges {
      node {
        data {
          Name
          Path
        }
      }
    }
  }
}
`
